<template>
  <div class="login-wrapper">
    <div class="scroll-view">
      <div class="flex-cover top">
        <div class="logo" v-if="logoShow">
          <img :src="logoShow | $imageUrl" :alt="systemName" />
        </div>
        <div class="title">
          <p :style="{ background: mainColor }">{{ systemName }}报备系统</p>
        </div>
      </div>
      <div class="login-form">
        <van-field
          v-model="username"
          name="username"
          placeholder="请填写手机号码"
          autocomplete="off"
          clearable
          ref="loginUsername"
          @keydown.enter.native="loginConfirm"
        >
          <template #left-icon>
            <van-icon name="system-username" class="login-icon" />
          </template>
        </van-field>
        <van-field
                v-if="loginType == 'password'"
                v-model="password"
                :type="passwordShow ? '' : 'password'"
                name="password"
                placeholder="请填写密码"
                right-icon="eye"
                :class="{show:passwordShow}"
                @click-right-icon="passwordShow=!passwordShow"
                ref="loginPassword"
                @keydown.enter.native="login"
            >
                <template #left-icon>
                    <van-icon name="system-password" class="login-icon" />
                </template>
            </van-field>
        <van-field
          v-else
          v-model="code"
          center
          ref="codeFocus"
          placeholder="短信验证码"
          @keydown.enter.native="loginConfirm"
        >
          <template #left-icon>
            <van-icon name="system-yanzhengma2-01" class="login-icon base" />
          </template>
          <template #button>
            <van-button
              size="mini"
              type="info"
              :disabled="true"
              v-if="countdown"
              >{{ num }}秒后可重新获取</van-button
            >
            <van-button
              type="info"
              size="mini"
              @click="loginStart"
              :disabled="loading"
              v-else
              >{{ isInit ? "重新获取" : "获取验证码" }}</van-button
            >
          </template>
        </van-field>
        <div class="login-btn" @click="login" :style="btnColor">
          登录
        </div>
        <div class="toolbar">
          
          <div class="item" @click="loginTypeChange">{{loginType == 'password' ? '短信登录' : '密码登录'}}</div>
          <router-link :to="{ name: 'userAgreement' }" class="item"
            >用户协议</router-link
          >
          <router-link :to="{name:'retrievePassword'}" class="item">找回密码</router-link>
          
          <router-link :to="{ name: 'register' }" class="item"
            >分销商注册</router-link
          >
        </div>
        <div class="wechat-login-cover">
          <div class="wechat-login" @click="wechatLogin">
            <div class="wechat-login-icon">
              <van-icon name="wechat" color="#ffffff" size="28" />
            </div>
            <p>微信登录</p>
          </div>
        </div>
      </div>
    </div>
    <verify-code
      v-model="verifyCodeShow"
      @confirm="getCheckCode"
      ref="verifyCode"
      :loading="codeLoading"
    ></verify-code>
  </div>
</template>

<script>
import dataStorage from "@/utils/dataStorage";
import verifyCode from "@/components/common/verify-code";
import api from "@/api";
import { isPhone } from "@/utils";
export default {
  components: {
    verifyCode
  },
  data() {
    return {
      username: "",

      code: "",

      checked: false,

      password:'',

      passwordShow: false,

      logoHm: require("@/assets/images/login_logo_hm.png"),

      logo: require("@/assets/images/login_logo.png"),

      verifyCodeShow: false,
      //是否在倒计时
      countdown: false,
      //倒计时秒数
      countdownSecond: 60,
      num: 0,
      inv_id: null,
      loading: false,
      isInit: false,
      status: "loginStart",
      codeLoading: false,
      loginType:'password'
    };
  },
  computed: {
    btnColor() {
      return {
        "background-image": `-webkit-linear-gradient(left, ${this.mainColor}, ${this.gradientColor})`,
        "background-image": `linear-gradient(to right, ${this.mainColor},  ${this.gradientColor})`,
      };
    },
    mainColor() {
      return this.$store.state.userInfo?.company?.color;
    },
    gradientColor() {
      return this.$store.state.userInfo?.company?.gradientColor;
    },

    backRouteName() {
      return this.$store.state.userInfo.backRouter;
    },

    logoShow() {
      return this.$store.state.userInfo?.company?.logo;
    },

    systemName() {
      return this.$store.state.userInfo?.company?.title;
    },
  },
  methods: {
    //微信登录
    wechatLogin() {
      this.$toast.loading({
        message: "正在登录 ...",
        duration: 0,
        forbidClick: true,
      });

      api.wxCode({
        options: {
          url:
            "https://" + window.location.host + this.$route.path,
        },

        success: (res) => {
          if (res.type == 200) {
            window.location.href = res.data;
          } else {
            this.$dialog
              .alert({
                title: "登录失败",

                message: res.msg || "微信登录失败，请求异常，请稍后重试",
              })
              .then(() => {});
          }
        },

        error: (err) => {
          console.log(err);

          this.$dialog
            .alert({
              title: "登录失败",

              message: "微信登录失败，请求异常，请稍后重试",
            })
            .then(() => {});
        },

        complete: () => {
          this.$toast.clear();
        },
      });
    },
    getCheckCode(verifyCode) {
      this.loading = true;
      this.codeLoading = true;
      api.getCode({
        options: {
          username: this.username,
          ...verifyCode,
        },
        success: (res) => {
          if (res.type == 200) {
            this.verifyCodeShow = false;
            this.$toast({
              message: "已成功发送验证码",
              position: "bottom",
            });
            this.countdownStart();
            this.isInit = true;
            this.status = "checkCode";
            //聚焦
            this.$nextTick(() => {
              this.$refs.codeFocus.focus();
            });
          } else {
            this.$toast(res.msg);
            this.$refs.verifyCode?.getVerifyCode &&
              this.$refs.verifyCode?.getVerifyCode();
          }
        },
        error: (err) => {
          console.log(err);
          this.$refs.verifyCode?.getVerifyCode &&
            this.$refs.verifyCode?.getVerifyCode();
        },
        complete: () => {
          this.loading = false;
          this.codeLoading = false;
        },
      });
    },
    //开始倒计时
    countdownStart() {
      //开始倒计时
      this.countdown = true;
      this.num = this.countdownSecond;
      this.num--;
      this.inv_id = setInterval(() => {
        this.num--;
        if (!this.num) {
          this.countdown = false;
          clearInterval(this.inv_id);
        }
      }, 1000);
    },
    //停止倒计时
    countdownEnd() {
      clearInterval(this.inv_id);
      this.countdown = false;
      this.num = 0;
    },
    selected() {
      //每次都要储存状态
      dataStorage.StorageSetter("savePassword", this.checked);
    },
    loginConfirm() {
      if (this.status == "loginStart") {
        this.loginStart();
      } else {
        this.login();
      }
    },
    login() {
      if (!this.username) {
        this.$toast({
          position: "bottom",
          message: "手机号不能为空",
        });
        this.$refs.loginUsername.focus();
        return;
      }
      if (!isPhone(this.username)) {
          this.$toast({
            position: "bottom",
            message: "手机号格式不正确",
          });
          this.$refs.loginUsername.focus();
          return;
      }
      if(this.loginType == 'code'){
        if (!this.code) {
          this.$toast({
            position: "bottom",
            message: "验证码不能为空",
          });
          this.$refs.codeFocus.focus();
          return;
        }
      }else{
        if (!this.password) {
          this.$toast({
            position: "bottom",
            message: "密码不能为空",
          });
          this.$refs.loginPassword.focus();
          return;
        }
      }
      this.$toast.loading({
        duration: 0,
        message: "登录中...",
        forbidClick: true,
        cancelButtonColor: "#00a0e4",
      });
      if(this.loginType == 'password'){
         api.loginPassword({
          options: {
            username: this.username,
            password: this.password,
          },
          success: (res) => {
            if (res.type == 200) {
              this.$store.commit("initData", res.data);
              this.$nextTick(() => {
                this.$router.replace({ name: "home" });
              });
            } else {
              this.$dialog.alert({
                title: "登录失败",
                message: res.msg || "登陆失败，原因不明，请联系管理员",
              });
            }
          },
          error: (err) => {
            console.log(err);
            this.$dialog.alert({
              title: "登陆失败",
              message: `登录异常，请稍后重试,${err}`,
            });
          },

          complete: () => {
            this.$toast.clear();
          },
        });
      }else{
        api.login({
          options: {
            username: this.username,
            code: this.code,
          },
          success: (res) => {
            if (res.type == 200) {
              this.$store.commit("initData", res.data);
              this.$nextTick(() => {
                this.$router.replace({ name: "home" });
              });
            } else {
              this.$dialog.alert({
                title: "登录失败",
                message: res.msg || "登陆失败，原因不明，请联系管理员",
              });
            }
          },
          error: (err) => {
            console.log(err);
            this.$dialog.alert({
              title: "登陆失败",
              message: `登录异常，请稍后重试,${err}`,
            });
          },

          complete: () => {
            this.$toast.clear();
          },
        });
      }
    },
    signByWx(code){
      this.$toast.loading({
        duration: 0,
        message: "登录中...",
        forbidClick: true,
        cancelButtonColor: "#00a0e4",
      });
      api.signByWx({
        options: {
          wxCode:code
        },
        success: (res) => {
          if (res.type == 200) {
            this.$store.commit("initData", res.data);
            this.$nextTick(() => {
              this.$router.replace({ name: "home" });
            });
          } else {
            this.$dialog.alert({
              title: "登录失败",
              message: res.msg || "登陆失败，原因不明，请联系管理员",
            });
          }
        },
        error: (err) => {
          console.log(err);
          this.$dialog.alert({
            title: "登陆失败",
            message: `登录异常，请稍后重试,${err}`,
          });
        },

        complete: () => {
          this.$toast.clear();
        },
      });
    },
    loginStart() {
      if (!this.username) {
        this.$toast({
          position: "bottom",
          message: "手机号不能为空",
        });
        this.$refs.loginUsername.focus();
        return;
      }
      if (!isPhone(this.username)) {
        this.$toast({
          position: "bottom",
          message: "手机号格式不正确",
        });
        this.$refs.loginUsername.focus();
        return;
      }
      //每次获取都拉起机器验证码
      this.verifyCodeShow = true;
    },
    loginTypeChange(){
      this.loginType = this.loginType == 'password' ? 'code' : 'password';
    }
  },
  activated(){
    //获取参数
    if(this.$route.query.code){
        this.signByWx(this.$route.query.code);
    }
  }
};
</script>

<style lang="less">
.login-wrapper {
  height: 100%;

  .scroll-view {
    height: 100%;

    overflow: hidden;

    overflow-y: scroll;

    padding-bottom: 30px;
  }
  .wechat-login-cover {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    .wechat-login {
      display: flex;
      flex-direction: column;
      align-items: center;
      .wechat-login-icon {
        width: 40px;
        height: 40px;
        background: #46bb36;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-size: 13px;
        padding-top: 5px;
        color: #777777;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }

  .top {
    display: flex;

    flex-direction: column;

    justify-content: center;

    align-items: center;

    min-height: 30vh;
  }

  .logo {
    width: 200px;

    height: 200px;

    position: relative;

    left: -5px;

    img {
      width: 100%;

      height: 100%;
    }
  }

  .m-10 {
    margin-top: 10px;
  }

  .title {
    text-align: center;

    p {
      display: inline-block;

      padding: 6px 20px;

      font-size: 16px;

      color: #ffffff;

      border-radius: 25px;

      &.main-color_bg_hm {
        background: #ffae00;
      }

      &.main-color_bg {
        background: #00a0e4;
      }
    }
  }

  .login-form {
    padding: 0 30px;
    margin-top: 30px;
    .form-icon {
      font-size: 22px;
    }
  }

  .login-btn {
    height: 40px;

    line-height: 40px;

    text-align: center;

    font-size: 20px;

    color: #ffffff;
    border-radius: 25px;

    margin-top: 30px;

    transition: opacity 0.3s ease;

    &:active {
      opacity: 0.6;
    }
  }

  .login-icon {
    font-size: 22px;

    margin-right: 10px;

    &.base {
      font-size: 30px;
      margin-right: 3px;
      position: relative;
      left: -4px;
    }
  }

  .van-cell {
    font-size: 16px;

    .van-field__control {
      padding-top: 3px;
    }
  }

  .toolbar {
    display: flex;

    justify-content: center;

    padding-top: 15px;

    .item {
      font-size: 13px;

      color: #00a0e4;

      padding: 0 10px;

      opacity: 0.8;
    }
  }

  .login-save {
    padding: 10px 15px 0;

    .item {
      color: #00a0e4;

      font-size: 13px;
    }

    .van-checkbox__icon {
      font-size: 16px;
    }

    .van-checkbox__label {
      font-size: 14px;

      position: relative;

      color: #666;
    }

    .active .van-checkbox__label {
      color: #00a0e4;
    }
  }
}
</style>
