<template>
    <div class="house-list-wrapper">
        <div class="flex-box">
            <div class="hd">
                <van-nav-bar left-arrow safe-area-inset-top class="search" @click-left="back" right-text="搜索"
                    @click-right="searchHouse">
                    <template #title>
                        <van-search v-model="keyword" class="sm border" shape="round" placeholder="请输入楼盘名称"
                            @search="searchHouse" ref="recordListSearch" />
                    </template>
                </van-nav-bar>
                <div class="menu">
                    <van-dropdown-menu>
                        <van-dropdown-item :title="areaTitle" ref="listCityMenu">
                            <van-tree-select :items="cityList" :active-id.sync="searchForm.areaId"
                                :main-active-index.sync="areaIndex" @click-item="selectCity" @click-nav="selectCityNav" />
                        </van-dropdown-item>
                        <van-dropdown-item :title="priceTitle" :options="priceList" v-model="searchForm.price"
                            @change="selectPrice" />
                        <van-dropdown-item :title="houseTeseTitle" :options="houseTeseList" v-model="searchForm.houseTese"
                            @change="selectHouseTese" />
                        <!-- <van-dropdown-item title="更多" class="more">
                            <more-select></more-select>
                        </van-dropdown-item>     -->
                    </van-dropdown-menu>
                </div>
            </div>
            <div class="bd" ref="houseListScrollView">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="100" @load="onLoad"
                        :error.sync="error" error-text="请求失败，点击重新加载">
                        <!-- <div class="house-item border_bottom" v-for="(item,index) in houseList" :key="index" @click="gotoDetail(item.id)">    
                            <div class="house-item-thumb">
                                <img :alt="item.name" v-lazy="$getImageUrl(item.buildingDiagram)">
                                <div class="video-icon p-a-m-c" v-if="item.buildingVideo"><van-icon name="system-icon_play" /></div>
                                <div class="addr" v-if="item.regionName">{{item.regionName}}</div>
                                <div class="vr-icon" v-if="item.buildingVr"><van-icon name="system-vr-2" /></div>
                            </div>
                            <div class="house-item-ctn">
                                <p class="house-name">{{item.name}}</p>
                                <p class="house-price">{{item|totalPrice}}</p>
                                <p class="house-type" v-if="item.tags && item.tags.length>0">
                                    <span v-for="(_item,_index) in item.tags" :key="_index">{{_item}}</span>
                                </p>
                                <p class="house-record-text" v-if="item.incentiveTimeStr">
                                  <span>{{item.incentiveTimeStr}}</span>
                                </p>
                                <p class="house-brokerage-text" v-if="loginReady">{{parseInt(item.commissionPoint) ? housePointTeset(item.commissionPoint) ? item.commissionPoint : '佣金 ' + item.commissionPoint +"%" : ''}}</p>
                                <p class="house-brokerage-text" v-if="(item.reportProtectDays || item.reportProtect) && loginReady">
                                    报备保护期：{{item.reportProtectDays ? item.reportProtectDays+'天':''}}{{item.reportProtect ? item.reportProtect +'小时' : ''}}
                                </p>  
                                <p class="house-brokerage-text" v-if="(item.takeLookProtect || item.takeLookProtectDays) && loginReady">
                                    带看保护期：{{item.takeLookProtectDays ? item.takeLookProtectDays+'天':''}}{{item.takeLookProtect ? item.takeLookProtect +'小时' : ''}}
                                </p>
                                <p class="house-brokerage-text" v-if="item.commissionDeveloperDescription && loginReady">
                                    报备渠道：{{item.commissionDeveloperDescription}}
                                </p>
                            </div>
                        </div> -->
                        <div class="index-house-item u-flex-col border-bottom" v-for="(item, index) in houseList"
                            :key="index" @click="gotoDetail(item.id)">
                            <div class="u-flex u-col-top">
                                <div class="index-house-item__thumb">
                                    <img :alt="item.name" v-lazy="$getImageUrl(item.buildingDiagram)" />
                                    <div class="video-icon p-a-m-c" v-if="item.buildingVideo"><van-icon name="system-icon_play" /></div>
                                    <div class="vr-icon" v-if="item.buildingVr"><van-icon name="system-vr-2" /></div>
                                </div>
                                <div class="index-house-item__content u-flex-auto-w">
                                    <div class="u-flex index-house-item__content_one u-row-between">
                                        <div class="u-flex text_1 u-flex-auto-w">
                                            <div class="text_1_name u-line-1">{{ item.name }}</div>
                                        </div>
                                        <div class="tag_size">
                                            <!-- <u-tag size="mini" class="status-icon" :text="item.sellingStatusCn"
                                                :bgColor="item.sellingStatus | filterTag(sellingStatusList)" :borderColor="item.sellingStatus | filterTag(sellingStatusList)
                                                    " color="#ffffff">
                                            </u-tag> -->
                                            <van-tag type="primary" :key="index"  text-color="#ffffff" :color="item.sellingStatus|filterTag(sellingStatusList)">{{ item.sellingStatusCn }}</van-tag>
                                        </div>
                                    </div>
                                    <div class="u-flex u-row-between index-house-item__content_Two" v-if="item.regionName">
                                        <div class="text_2_name">{{ item.regionName }}</div>
                                    </div>
                                    <div class="u-flex u-flex-wrap tag_size theme-cover">
                                        <van-tag plain type="primary" v-for="(tag,index) in item.themeName" :key="index" color="#999999" text-color="#666666">{{ tag }}</van-tag>
                                    </div>
                                    <div class="u-flex index-house-item__content_Three u-flex">
                                        <div class="text_3_price">{{ item.showPrice.price }}{{ item.showPrice.unit }}
                                        </div>
                                        <div class="text_3_area u-line-1 u-flex-auto-w" v-if="item.buildingArea">建面 {{
                                            item.buildingArea }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="baobei u-flex"
                                v-if="(item.reportProtectDays || item.takeLookProtectDays) && loginReady">
                                <div class="baobei_text">报备保护期：{{ item.reportProtectDays }}天
                                </div>
                                <div class="baobei_text" v-if="item.takeLookProtectDays">带看保护期：{{ item.takeLookProtectDays
                                }}天</div>
                            </div>
                            <div class="baobei u-flex"
                                v-if="item.commissionPolicy && (item.commissionPolicy.secondaryPoint || item.commissionPolicy.secondaryAward) && loginReady">
                                <div class="baobei_text"
                                    v-if="item.commissionPolicy.secondaryPoint">二级点位：{{
                                        item.commissionPolicy.secondaryPoint | pointFilter }}</div>
                                <div class="baobei_text" v-if="item.commissionPolicy.secondaryAward">二级成交奖：{{
                                    item.commissionPolicy.secondaryAward }}</div>
                            </div>
                            <div class="house-record-text" v-if="item.incentiveTimeStr">
                                <span>{{ item.incentiveTimeStr }}</span>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script>
import backMixin from "@/mixins/back";
import api from "@/api";
import { findIndex, cityListKeyChange } from "@/utils";
export default {

    mixins: [backMixin],


    data() {

        return {
            keyword: "",
            //是否到底
            finished: false,
            //是否加载
            loading: false,
            //是否下拉结束
            refreshing: false,
            //是否报错
            error: false,
            //页码
            page: 1,
            //一次获取的数量
            size: 8,
            //楼盘列表
            houseList: [],
            //区域标题
            areaTitle: '区域',
            oldAreaId: 0,
            //当前选中的区域index
            areaIndex: 0,
            searchForm: {
                price: 0
            },
            priceTitle: '价格',
            //类型标题
            houseTeseTitle: '类型',
            sellingStatusList: [
                { label: "在售", value: "SELLING", color: "#4285F6" },
                { label: "待售", value: "PENDING", color: "#FF9420" },
                { label: "售罄", value: "SELL_OUT", color: "#93A4D0" },
                { label: "认筹", value: "OFFER_BUY", color: "#A066FF" },
                { label: "即将开盘", value: "SELL_SOON", color: "#01C0AE" },
                { label: "停售", value: "STOP_SELL", color: "#FF5D5E" },
            ]
        }

    },
    computed: {

        cityList() {

            return cityListKeyChange(this.$store.state.userInfo?.cityList) || [{ text: '不限', id: 0, children: [] }];

        },

        priceList() {

            return this.$store.state.userInfo?.priceList || [{ text: '不限', value: 0 }];

        },

        houseTeseList() {

            return this.$store.state.userInfo?.houseTeseList || [{ text: '不限', value: 0 }]

        },

        loginReady() {

            return this.$store.state.userInfo?.ready;

        }
    },

    filters: {
        totalPrice(item) {
            return parseFloat(item.packagePrice) ? item.packagePrice + '万/套' : item.averagePrice ? item.averagePrice + '元/㎡' : '暂无';
        },
        protectValue(val) {
            if (!val) return '';
            if (val > 24) {
                return `${Math.floor(val / 24)} 天 ${Math.round(val % 24)} 小时`
            } else {
                return `${val} 小时`
            }
        },
        filterTag(val, sellingStatusList) {
            if (val) {
                let state = sellingStatusList.find((v) => {
                    return val === v.value;
                });
                return state.color;
            }
        },
        pointFilter(val) {
            if (!val) return '';
            return parseFloat(val);
        }
    },

    methods: {

        onLoad() {

            this.getHouseData();

        },

        //搜索楼盘数据

        searchHouse() {

            this.page = 1;

            this.$toast.loading({

                message: '正在加载...',

                forbidClick: true,

                duration: 0

            });

            let options = {

                page: this.page,

                pageSize: this.size,

                keyword: this.keyword,

            }

            options = Object.assign(options, this.searchForm);

            //重置一下

            api.getHouseList({
                options: options,
                success: (res) => {
                    if (res.type == 200) {
                        this.houseList = res.data.list;
                        this.error = false;
                        this.finished = this.$listLastCheck(res.data.list, this.size);
                        if (!this.finished) {

                            this.page++;

                        }

                    } else {
                        this.error = true;
                    }
                },

                error: (err) => {

                    console.log(err);

                    this.error = true;

                },

                complete: (res) => {

                    if (res.code && res.code != 200) {

                        this.error = true;

                    }

                    this.loading = false;

                    this.refreshing = false;

                    this.$toast.clear();

                }


            })



        },
        $totalPrice(item) {
            return parseFloat(item.packagePrice) ? {price:item.packagePrice,unit:'万/套起'}  : parseFloat(item.averagePrice) ? {price:item.averagePrice,unit:'元/㎡'} : {price:'暂无',unit:''};
        },

        //获取楼盘数据

        getHouseData(type) {

            let options = {

                page: this.page,

                pageSize: this.size,

                keyword: this.keyword,

            }

            options = Object.assign(options, this.searchForm);


            api.getHouseList({

                options: options,

                success: (res) => {

                    if (res.type == 200) {
                        res.data.list.forEach((el) => {
                            el.showPrice = this.$totalPrice(el);
                        });
                        //首先判断是否存在勾选状态的楼盘

                        if (type == 'refresh') {

                            this.houseList = res.data.list;

                            this.$toast({

                                message: "楼盘已更新",

                                position: "top"

                            });

                        } else {

                            this.houseList.push(...res.data.list);

                        }

                        this.error = false;
                        let last = this.$listLastCheck(res.data.list, this.size);
                        if (!last) {

                            this.page++;

                        }

                        this.finished = last;

                    } else {

                        this.error = true;

                    }


                },

                error: (err) => {

                    console.log(err);

                    this.error = true;

                },

                complete: (res) => {

                    if (res.code && res.code != 200) {

                        this.error = true;

                    }

                    this.refreshing = false;

                    this.loading = false;

                }


            })


        },


        onRefresh() {

            // 清空列表数据

            this.finished = true;

            // 重新加载数据

            // 将 loading 设置为 true，表示处于加载状态

            this.loading = true;

            this.page = 1;

            this.getHouseData('refresh');

        },

        //跳转

        gotoDetail(id) {

            //每次跳转都设置一次跳转内容

            this.$route.meta.scrollTop = this.$refs.houseListScrollView.scrollTop;

            this.$router.push({ path: `/house/detail/${id}` });

        },

        //选择地区

        selectCity(data) {

            //获取上级元素

            let _parent = this.cityList[this.areaIndex];

            //一致，确认是不限

            if (_parent.id == data.id) {

                this.areaTitle = _parent.text;

            } else {

                this.areaTitle = data.text;

            }

            if (data.id != this.oldAreaId) {

                this.$nextTick(() => {

                    this.searchHouse();

                })

            }

            this.$refs.listCityMenu.toggle(false);

            this.oldAreaId = data.id;

        },

        selectCityNav(index) {

            let item = this.cityList[index];

            if (item.children && item.children.length > 0) return;

            this.$set(this.searchForm, 'areaId', item.id);

            this.areaTitle = item.text;

            this.oldAreaId = item.id;

            this.$refs.listCityMenu.toggle(false);

            this.$nextTick(() => {

                this.searchHouse();

            })

        },

        //选择价格区间

        selectPrice(data) {

            this.priceTitle = data.text;

            this.searchHouse();

        },

        //选择楼盘特色

        selectHouseTese(data) {

            this.houseTeseTitle = data.text;

            this.searchHouse();

        },

        //检查是否包含百分号

        housePointTeset(data) {

            let reg = /%/g;

            return reg.test(data);

        }

    },

    activated() {

        this.$nextTick(() => {

            if (this.$route.meta.scrollTop) {

                this.$refs.houseListScrollView.scrollTop = this.$route.meta.scrollTop;

            }

            global.setTimeout(() => {

                if (this.$route.params.focus) {

                    let _input = this.$refs.recordListSearch.getElementsByTagName('input');

                    if (_input.length > 0) {

                        _input[0].focus();

                    }

                }

            }, 800)

        })

    },

    beforeDestroy() {

        this.$route.meta.scrollTop = 0;

    },


    created() {

        if (this.$route.query.feature) {

            //找到对应的特色id

            let index = findIndex(this.houseTeseList, (el) => {

                return this.$route.query.feature == el.value;

            });

            if (index != -1) {

                this.$nextTick(() => {

                    this.houseTeseTitle = this.houseTeseList[index].text;

                    this.$set(this.searchForm, 'houseTese', this.$route.query.feature);

                })

            }


        }



        //存在选择的城市

        if (this.$store.state.userInfo.currentCity) {

            let index = findIndex(this.$store.state.userInfo.cityList, (el) => {

                return el.value == this.$store.state.userInfo.currentCity.pid

            });

            this.areaIndex = index < 0 ? 0 : index;

            this.searchForm.areaId = this.$store.state.userInfo.currentCity.value;

            this.areaTitle = this.$store.state.userInfo.currentCity.text;

        }

    }



}
</script>

<style lang="less">
.house-list-wrapper {

    display: flex;

    flex-direction: column;

    height: 100%;

    width: 100%;

    .menu {

        border-bottom: 10px solid #f6f6f6;

        .more .van-popup {

            overflow: hidden;

        }

    }

    .bd {

        flex: 1;

        height: 0;

        overflow-y: auto;

        .house-item {

            padding: 20px 15px;

            display: flex;



            .house-item-thumb {

                width: 35%;

                height: 90px;

                margin-right: 10px;

                position: relative;

                .addr {

                    position: absolute;

                    left: 0;

                    bottom: 0;

                    padding: 2px 10px 3px;

                    background: rgba(0, 0, 0, .7);

                    color: #fff;

                }

                img {

                    width: 100%;

                    height: 100%;

                }

                .video-icon {

                    width: 30px;

                    height: 30px;

                    line-height: 32px;

                    background: rgba(0, 0, 0, .4);

                    border-radius: 50%;

                    text-align: center;

                    color: #fff;

                    .van-icon {

                        font-size: 14px;

                    }

                }

                .vr-icon {

                    position: absolute;

                    right: 3px;

                    bottom: 3px;

                    color: #fff;



                    .van-icon {

                        font-size: 16px;

                        text-shadow: 0 0 1px rgba(0, 0, 0, .4);

                    }

                }

            }

            .house-item-ctn {

                line-height: 22px;

                color: #555555;

                flex: 1;

                width: 0;

                display: flex;

                flex-direction: column;

                .house-name {

                    font-size: 16px;

                }

                .house-price {

                    font-size: 13px;

                }

                .house-type {

                    span {

                        padding-right: 5px;

                        margin-left: 5px;

                        position: relative;

                        &:after {

                            content: '';

                            position: absolute;

                            right: 0;

                            top: 45%;

                            width: 1px;

                            height: 60%;

                            background: #eee;

                            transform: translateY(-50%);

                        }

                        &:first-child {

                            margin-left: 0;

                        }

                        &:last-child:after {

                            display: none;

                        }

                    }

                }

                .house-record-text {

                    padding: 5px 0;

                    span {

                        display: inline-block;

                        line-height: 20px;

                        padding: 3px 5px 1px;

                        background: #f6f6f6;

                        width: auto;

                        font-size: 12px;

                        color: #999999;

                    }


                }

                .house-brokerage-text {

                    padding-top: 5px;

                    color: #00a0e4;

                    font-size: 14px;

                    letter-spacing: 1px;

                }




            }

        }

        .index-house-item {
            padding: 20.5px 18px 20.5px 18px;
            border-bottom: 1px solid #eeeeee;

            .index-house-item__thumb {
                width: 105px;
                height: 88px;
                background: #f2f2f2;
                border-radius:3px;
                margin-right: 18px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .video-icon {
                    width: 30px;

                    height: 30px;

                    line-height: 32px;

                    background: rgba(0, 0, 0, 0.4);

                    border-radius: 50%;

                    text-align: center;

                    color: #fff;

                    .van-icon {
                        font-size: 14rpx;
                    }

                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }

                .vr-icon {
                    position: absolute;

                    right: 3px;

                    bottom: 3px;

                    color: #fff;

                    .van-icon {
                        font-size: 16px;

                        text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
                    }
                }
            }
            .van-tag+.van-tag{
                margin-left:5px;
            }
            .index-house-item__content {
                flex: 1 1 auto;

                &_one {
                    margin-bottom: 5px;

                    .text_1 {
                        font-size: 15px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        padding-right: 10px;

                        .text_1_name {
                            width: 100%;
                            margin-right:11px;
                        }
                    }
                }

                &_Two {
                    margin-bottom: 5px;

                    .text_2_name {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #777777;
                    }
                }

                &_Three {
                    margin-bottom: 5px;

                    .text_3_price {
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ff453f;
                    }

                    .text_3_area {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #777777;
                        padding-left: 15px;
                    }
                }

   

                .theme-cover {
                    margin-bottom: 5px;
                }

                .tag-item {
                    margin-right: 5px;
                }

                .tag-item ::v-deep .u-tag-wrapper {
                    margin: 0 5px 5px 0;

                    .u-tag {
                        border: 1px solid #b5b5b5;

                        .u-tag__text {
                            color: #777777;
                        }
                    }
                }
            }

            .baobei {
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4285f6;
                margin-top: 10px;
            }

            .baobei_text:first-child{
                margin-right:10px;
            }

            .house-record-text {
                padding: 10px 0 0;

                span {
                    display: inline-block;
                    line-height: 20px;
                    padding: 3px 5px 1px;
                    background: #f6f6f6;
                    width: auto;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }

    }

}
</style>
